import { Grid } from "@mui/material";
import { Container } from "@mui/system";
import { useLocation } from "react-router";
import styled from "styled-components";
import GoogleAd from "./GoogleAd";

const CustomContainer = styled(Container)`
	height: (100vh);
	max-width: 100vw !important;
	background-color: transparent;
	// margin: 0 0 0 0 !important;
	margin-top: ${(props) =>
		props.login || props.noheader
			? "calc(3rem)"
			: "calc(4rem + 4rem) !important"};
	margin-bottom: 6rem;
	// overflow: auto;
`;

const PageContainer = (props) => {
	const { search } = useLocation();

	return (
		<CustomContainer noheader={!!search.includes("noheader")} {...props}>
			<Grid container xs={12}>
				<Grid container item xs={12}>
					<Grid
						item
						xs={2}
						sx={{
							width: "100%",
							height: "auto",
							padding: "0 1rem",
							// margin: '1rem 0',
						}}
					>
						{
							// <Box className='' sx={{ width: '300px', height: '250px' }}>
							<GoogleAd
								googleAdId={"ca-pub-9173866185064071"}
								slot="6638633547"
								style={{
									display: "inline-block",
									width: "100%",
									height: "100%",
								}}
							/>
							// </Box>
						}
					</Grid>
					<Grid item xs={8} sx={{ height: "auto" }}>
						{props.children}
					</Grid>
				</Grid>
			</Grid>
		</CustomContainer>
	);
};

export default PageContainer;
