import { useState } from "react";
import { omit } from "lodash";

export const useForm = (callback) => {
	//Form values
	const [values, setValues] = useState({});
	//Errors
	const [errors, setErrors] = useState({});

	const validate = (event, name, value) => {
		//A function to validate each input values

		switch (name) {
			case "email":
				if (!new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(value)) {
					setErrors({
						...errors,
						email: "Enter a valid email address",
					});
				} else {
					let newObj = omit(errors, "email");
					setErrors(newObj);
				}
				break;

			case "password":
				if (!new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/).test(value)) {
					setErrors({
						...errors,
						password: "Password should contain atleast 8 charaters, containing uppercase, lowercase and numbers",
					});
				} else {
					let newObj = omit(errors, "password");
					setErrors(newObj);
				}
				break;

			default:
				break;
		}
	};

	//A method to handle form inputs
	const handleChange = (event) => {
		//To stop default events
		event.persist();

		let name = event.target.name;
		let val = event.target.value;

		validate(event, name, val);
		//Let's set these values in state

		setValues({
			...values, //spread operator to store old values
			[name]: val,
		});
	};

	const handleSubmit = (e) => {
		if (e) e.preventDefault();

		if (Object.keys(errors).length === 0 && Object.keys(values).length !== 0) {
			callback();
		}
	};

	return {
		values,
		errors,
		handleChange,
		handleSubmit,
	};
};
