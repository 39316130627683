import { Grid } from "@mui/material";
import React from "react";
import styled from "styled-components";
import SiteLogo from "../../assets/original.png";
import Logo from "../../assets/s-logo.png";
import SeleniumLogo from "../../assets/selenium.png";
import CenterBox from "../../components/CenterBox";
import PageCard from "../../components/PageCard";
import PageContainer from "../../components/PageContainer";

const CardMedia = styled("img")({
	width: "100px",
	border: "1px solid #f2f2f2",
});

const DnD = () => {
	return (
		<PageContainer>
			<CenterBox>
				<PageCard>
					<Grid container spacing={2} height={"100%"}>
						<Grid item xs={6}>
							<CenterBox
								border={"2px solid gray"}
								sx={{
									flexDirection: "column",
									justifyContent: "space-around",
								}}
								onDragStart={(e) => {
									console.log(e.target.id);
									e.dataTransfer.setData("text/plain", e.target.id);
								}}
							>
								{<CardMedia id="site-logo" component={"img"} src={SiteLogo} />}
								{<CardMedia id="s-logo" component={"img"} src={Logo} />}
								{
									<CardMedia
										id="selenium-logo"
										component={"img"}
										src={SeleniumLogo}
									/>
								}
							</CenterBox>
						</Grid>
						<Grid item xs={6}>
							<CenterBox
								border={"2px solid gray"}
								onDrop={(e) => {
									console.log(
										document.getElementById(e.dataTransfer.getData("text"))
									);
									var newelem = document.getElementById(
										e.dataTransfer.getData("text")
									);
									e.target.appendChild(newelem);
									e.preventDefault();
								}}
								onDragOver={(e) => e.preventDefault()}
								sx={{
									flexDirection: "column",
									justifyContent: "space-around",
								}}
							></CenterBox>
						</Grid>
					</Grid>
				</PageCard>
			</CenterBox>
		</PageContainer>
	);
};

export default DnD;
