import {
	Card,
	CardMedia,
	CardContent,
	Typography,
	Button,
	InputLabel,
	Grid,
	TextField,
	IconButton,
	Dialog,
	DialogContent,
	DialogContentText,
	DialogActions,
	Zoom,
} from '@mui/material';
import { Info } from '@mui/icons-material';
import Logo from '../../assets/original.png';
import React, { useState } from 'react';
import CenterBox from '../../components/CenterBox';
import PageContainer from '../../components/PageContainer';
import ImageBox from '../../components/ImageBox';
import { useForm } from '../../helpers';
import Error from '../../components/Error';
import { useNavigate } from 'react-router';
import { Box } from '@mui/system';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Zoom ref={ref} {...props} />;
});

const Login = () => {
	const navigate = useNavigate();

	const [loginError, setLoginError] = useState('');
	const [showInfoModal, setShowInfoModal] = useState(false);
	// const [loginError, setLoginError] = useState('');

	var handleLogin = () => {
		console.log('Called');
		if (
			values.email === 'admin@automationtesting.in' &&
			values.password === 'Admin1234'
		) {
			navigate('/form');
		} else {
			setLoginError('Login details incorrect');
		}
	};

	const handleCloseInfoModal = () => {
		setShowInfoModal(false);
	};

	const { handleChange, values, errors, handleSubmit } = useForm(handleLogin);

	return (
		<PageContainer maxWidth='md' login>
			<CenterBox>
				<Card sx={{ maxWidth: 345 }}>
					{
						// <CardMedia children={<img src={Logo} />}  alt="logo" />
					}
					<Box fullWidth sx={{ display: 'flex', justifyContent: 'flex-end' }}>
						<IconButton onClick={() => setShowInfoModal(true)}>
							<Info />
						</IconButton>
					</Box>
					<CardMedia>
						<ImageBox src={Logo} height='120' alt='logo' />
					</CardMedia>
					<CardContent>
						<Typography
							gutterBottom
							variant='h4'
							component='div'
							textAlign='center'
							textTransform='uppercase'
							fontWeight='500'>
							Login
						</Typography>
						<Box
							component='form'
							noValidate
							autoComplete='off'
							onSubmit={handleSubmit}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<TextField
										label='Email'
										name='email'
										type='email'
										fullWidth
										placeholder='admin@automationtesting.in'
										required
										autoFocus
										onChange={handleChange}
										error={errors.email && true}
										helperText={errors.email}
										variant='outlined'
									/>
								</Grid>
								<Grid item xs={12}>
									<InputLabel shrink required>
										Password
									</InputLabel>
									<TextField
										label='Password'
										name='password'
										type='password'
										fullWidth
										placeholder='Admin1234'
										onChange={handleChange}
										required
										error={errors.password && true}
										helperText={errors.password}
										variant='outlined'
									/>
									{loginError && <Error>{loginError}</Error>}
								</Grid>
								<Grid
									container
									item
									spacing={2}
									xs={12}
									alignItems={'center'}
									justifyContent={'center'}>
									<Grid
										item
										xs={6}
										alignContent='center'
										justifyContent='center'>
										<Button
											size='large'
											variant='contained'
											type='submit'
											fullWidth
											disabled={!!errors.email || !!errors.password}>
											LOGIN
										</Button>
									</Grid>
									<Grid
										item
										xs={6}
										alignContent='center'
										justifyContent='center'>
										<Button
											size='large'
											variant='outlined'
											type='button'
											color='secondary'
											fullWidth
											onClick={() => navigate('/form')}>
											SKIP LOGIN
										</Button>
									</Grid>
								</Grid>
							</Grid>
						</Box>
					</CardContent>
				</Card>
			</CenterBox>
			<Dialog
				fullWidth
				maxWidth={'sm'}
				TransitionComponent={Transition}
				open={showInfoModal}
				onClose={handleCloseInfoModal}>
				<DialogContent>
					<DialogContentText>
						<small className='fst-italic'>
							Please use below credentials to login (as provided in the input
							placeholders).
							<hr />
						</small>

						<p>
							Email:{' '}
							<span className='text-dark'>admin@automationtesting.in</span>
						</p>
						<p>
							Password: <span className='text-dark'>Admin@1234</span>
						</p>
					</DialogContentText>
					<Box
						noValidate
						component='form'
						sx={{
							display: 'flex',
							flexDirection: 'column',
							m: 'auto',
							width: 'fit-content',
						}}></Box>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseInfoModal}>Close</Button>
				</DialogActions>
			</Dialog>
		</PageContainer>
	);
};

export default Login;
