import { Card } from "@mui/material";
import styled from "styled-components";

const PageCard = styled(Card)`
	margin-top: 0rem;
	border-radius: 15px !important;
	width: 100vw;
	height: 100%;
	min-height: 400px;
	padding: 0 0.5rem 1rem 0.5rem;
	margin: 0 0 0.5rem 0;
	// overflow: auto !important;
`;

export default PageCard;
