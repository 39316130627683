import { Box, Tab, Tabs } from '@mui/material';
import React from 'react';
import CenterBox from '../../components/CenterBox';
import PageCard from '../../components/PageCard';
import PageContainer from '../../components/PageContainer';
import TabPanel from '../../components/TabPanel';
import { a11yProps } from '../../utils/common';

const Frames = () => {
	const [value, setValue] = React.useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<PageContainer>
			<CenterBox>
				<PageCard>
					<Box
						sx={{
							flexGrow: 1,
							bgcolor: 'background.paper',
							display: 'flex',
							flexDirection: 'column',
							height: '100%',
						}}>
						<Tabs
							// orientation='vertical'
							// variant='scrollable'
							value={value}
							onChange={handleChange}
							aria-label='Vertical tabs example'
							sx={{ borderRight: 1, borderColor: 'divider' }}>
							<Tab
								label='Single iFrame'
								{...a11yProps(0)}
								sx={{ fontSize: '1rem', textTransform: 'unset' }}
							/>
							<Tab
								label='Nested iFrames'
								{...a11yProps(1)}
								sx={{ fontSize: '1rem', textTransform: 'unset' }}
							/>
						</Tabs>
						<Box
							display={'flex'}
							justifyContent='center'
							width={'100%'}
							marginTop={'3rem'}>
							<TabPanel value={value} index={0} style={{ textAlign: 'center' }}>
								{
									<iframe
										src='/'
										id='single_iframe'
										name='single iframe'
										style={{
											float: 'left',
											height: '65vh',
											width: '60vw',
											// border: '1px solid gray',
											borderWidth: '2px',
											borderColor: 'initial',
											borderStyle: 'inset',
											borderImage: 'initial',
										}}
										title='Single iFrame'></iframe>
								}
								{
									// <iframe
									// src='https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Ffacebook&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId'
									// width='340'
									// height='500'
									// style={{ border: 'none', overflow: 'hidden' }}
									// scrolling='no'
									// frameborder='0'
									// allowfullscreen='true'
									// title='Facebook iFrame'
									// allow='autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share'></iframe>
								}
							</TabPanel>
							<TabPanel value={value} index={1} style={{ textAlign: 'center' }}>
								<iframe
									// src='https://demo.automationtesting.in/MultipleFrames.html'
									src='/frames?noheader'
									id='multiple_iframes'
									name='Multiple iFrame'
									style={{
										float: 'left',
										height: '65vh',
										width: '60vw',
										// border: '1px solid gray',
										borderWidth: '2px',
										borderColor: 'initial',
										borderStyle: 'inset',
										borderImage: 'initial',
									}}
									title='Multiple iFrames'></iframe>
							</TabPanel>
						</Box>
					</Box>
				</PageCard>
			</CenterBox>
		</PageContainer>
	);
};

export default Frames;
