import {
	Button,
	Checkbox,
	FormControl,
	Grid,
	IconButton,
	Input,
	MenuItem,
	Paper,
	Popover,
	Select,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	TableSortLabel,
	Typography,
	useTheme,
} from '@mui/material';
import {
	Check,
	Add,
	Edit,
	DeleteForever,
	LastPage,
	FirstPage,
	KeyboardArrowRight,
	KeyboardArrowLeft,
} from '@mui/icons-material';
import { Box } from '@mui/system';
import React from 'react';
import { useRecoilState } from 'recoil';
import CenterBox from '../../components/CenterBox';
import PageCard from '../../components/PageCard';
import PageContainer from '../../components/PageContainer';
import formAtom from '../../recoil/formAtom';
import { useNavigate } from 'react-router';
import { visuallyHidden } from '@mui/utils';

function TablePaginationActions(props) {
	const theme = useTheme();
	const { count, page, rowsPerPage, onPageChange } = props;

	const handleFirstPageButtonClick = (event) => {
		onPageChange(event, 0);
	};

	const handleBackButtonClick = (event) => {
		onPageChange(event, page - 1);
	};

	const handleNextButtonClick = (event) => {
		onPageChange(event, page + 1);
	};

	const handleLastPageButtonClick = (event) => {
		onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
	};

	return (
		<Box
			sx={{
				flexShrink: 0,
				ml: 2.5,
				alignItems: 'flex-end',
			}}>
			<IconButton
				onClick={handleFirstPageButtonClick}
				disabled={page === 0}
				aria-label='first page'>
				{theme.direction === 'rtl' ? <LastPage /> : <FirstPage />}
			</IconButton>
			<IconButton
				onClick={handleBackButtonClick}
				disabled={page === 0}
				aria-label='previous page'>
				{theme.direction === 'rtl' ? (
					<KeyboardArrowRight />
				) : (
					<KeyboardArrowLeft />
				)}
			</IconButton>
			<IconButton
				onClick={handleNextButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label='next page'>
				{theme.direction === 'rtl' ? (
					<KeyboardArrowLeft />
				) : (
					<KeyboardArrowRight />
				)}
			</IconButton>
			<IconButton
				onClick={handleLastPageButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label='last page'>
				{theme.direction === 'rtl' ? <FirstPage /> : <LastPage />}
			</IconButton>
		</Box>
	);
}

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) {
			return order;
		}
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
	const {
		onSelectAllClick,
		order,
		orderBy,
		numSelected,
		rowCount,
		onRequestSort,
		headCells,
	} = props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead>
			<TableRow>
				<TableCell padding='checkbox'>
					<Checkbox
						color='primary'
						indeterminate={numSelected > 0 && numSelected < rowCount}
						checked={rowCount > 0 && numSelected === rowCount}
						onChange={onSelectAllClick}
						inputProps={{
							'aria-label': 'select all desserts',
						}}
					/>
				</TableCell>
				{headCells.map(({ id, numeric, disablePadding, label, sort = true }, idx) =>
					sort ? (
						<TableCell
							key={idx}
							align={numeric ? 'right' : 'left'}
							padding={disablePadding ? 'none' : 'normal'}
							sortDirection={orderBy === id ? order : false}>
							<TableSortLabel
								active={orderBy === id}
								direction={orderBy === id ? order : 'asc'}
								onClick={createSortHandler(id)}>
								{label}
								{orderBy === id ? (
									<Box component='span' sx={visuallyHidden}>
										{order === 'desc'
											? 'sorted descending'
											: 'sorted ascending'}
									</Box>
								) : null}
							</TableSortLabel>
						</TableCell>
					) : (
						<TableCell key={idx} sx={{ minWidth: '150px' }} align=''>
							{label}
						</TableCell>
					)
				)}
			</TableRow>
		</TableHead>
	);
}

const WebTable = () => {
	const [formState, setFormState] = useRecoilState(formAtom);
	const navigate = useNavigate();

	const headCells = [
		{
			label: 'Actions',
			sort: false,
		},
		{
			id: 'firstName',
			numeric: false,
			disablePadding: true,
			label: 'First Name',
		},
		{
			id: 'lastName',
			numeric: false,
			disablePadding: false,
			label: 'Last Name',
		},
		{
			id: 'gender',
			numeric: false,
			disablePadding: false,
			label: 'Gender',
		},
		{
			id: 'email',
			numeric: false,
			disablePadding: false,
			label: 'Email',
		},
		{
			id: 'phone',
			numeric: false,
			disablePadding: false,
			label: 'Phone',
		},
	];

	const [editId, setEditId] = React.useState('');
	const [deleteId, setDeleteId] = React.useState('');
	const [search, setSearch] = React.useState('');

	//Sort
	const [order, setOrder] = React.useState('asc');
	const [orderBy, setOrderBy] = React.useState('firstName');
	const [selectedDelete, setSelectedDelete] = React.useState([]);

	const [editItem, setEditItem] = React.useState({});
	const [showDeletePopup, setShowDeletePopup] = React.useState(false);
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(5);

	const [anchorElForDeletePopup, setAnchorElForDeletePopup] =
		React.useState(null);

	const genders = [
		{ name: 'Male', value: 'Male' },
		{ name: 'Female', value: 'Female' },
		{ name: 'Other', value: 'Other' },
	];

	const emptyRows =
		page > 0 ? Math.max(0, (1 + page) * rowsPerPage - formState.length) : 0;

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const updateFormState = (row) => {
		const newState = formState.map((item) => {
			if (item.id === row.id) {
				return { ...editItem };
			}
			return item;
		});
		setFormState(newState);
	};

	const handleCloseDeletePopup = () => {
		setShowDeletePopup(false);
		setDeleteId('');
		setAnchorElForDeletePopup(null);
	};

	const filterBySearch = (array) => {
		console.log(array, search);
		const lowercasedValue = search.toLowerCase().trim();
		if (lowercasedValue === '') return array;
		else {
			const filteredData = array.filter((item) => {
				return Object.keys(item).some((key) =>
					array.includes(key)
						? false
						: item[key].toString().toLowerCase().includes(lowercasedValue)
				);
			});
			return filteredData;
		}
	};

	const handleSelect = (event, name) => {
		const selectedIndex = selectedDelete.indexOf(name);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selectedDelete, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selectedDelete.slice(1));
		} else if (selectedIndex === selectedDelete.length - 1) {
			newSelected = newSelected.concat(selectedDelete.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selectedDelete.slice(0, selectedIndex),
				selectedDelete.slice(selectedIndex + 1)
			);
		}

		setSelectedDelete(newSelected);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelected = formState.map((n) => n.id);
			setSelectedDelete(newSelected);
			return;
		}
		setSelectedDelete([]);
	};

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const isSelected = (id) => selectedDelete.indexOf(id) !== -1;

	return (
		<PageContainer maxWidth='lg'>
			<CenterBox>
				<Paper component={PageCard} sx={{ width: '100%', overflow: 'hidden' }}>
					<Grid container xs={12} spacing={0}>
						<Grid item xs={12}>
							<Box>
								<small className='fst-italic text-secondary'>
									Double click on{' '}
									<Edit sx={{ fontSize: '1em' }} color='primary' /> icon to edit
									a row.
								</small>{' '}
								<small className='fst-italic text-secondary'>
									Right click on{' '}
									<DeleteForever sx={{ fontSize: '1em' }} color='error' /> icon
									to delete a record.
								</small>
							</Box>
						</Grid>
					</Grid>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
							padding: '0.25em',
						}}>
						<Box>
							<Button
								sx={{ margin: '0 0.5em 0 0em' }}
								onClick={() => navigate('/form')}
								variant='contained'>
								<Add />
								Add New
							</Button>
							<Button
								color='error'
								variant='contained'
								onClick={() => {
									const newState = formState.filter(
										(item) => selectedDelete.indexOf(item.id) === -1
									);
									setFormState(newState);
									setSelectedDelete([]);
								}}
								disabled={!selectedDelete.length}>
								<DeleteForever /> Delete Selected
							</Button>
						</Box>
						<Box>
							<Input
								type='text'
								placeholder='Search'
								onChange={(e) => setSearch(e.target.value)}
							/>
							{
								// <IconButton>
								// 	<Info />
								// </IconButton>
							}
						</Box>
					</Box>
					<TableContainer sx={{ maxHeight: '57vh' }}>
						<Table
							sx={{ minWidth: 650 }}
							aria-label='simple table'
							stickyHeader>
							<EnhancedTableHead
								numSelected={selectedDelete.length}
								order={order}
								orderBy={orderBy}
								onSelectAllClick={handleSelectAllClick}
								onRequestSort={handleRequestSort}
								rowCount={formState.length}
								headCells={headCells}
							/>
							<TableBody>
								{stableSort(
									filterBySearch(formState),
									getComparator(order, orderBy)
								)
									.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									.map((row, index) => {
										const isItemSelected = isSelected(row.id);
										const labelId = `enhanced-table-checkbox-${index}`;

										return editId !== row.id && editItem ? (
											<TableRow
												hover
												// onClick={(event) => handleSelect(event, row.id)}
												role='checkbox'
												aria-checked={isItemSelected}
												tabIndex={-1}
												key={row.id}
												selected={isItemSelected}>
												<TableCell padding='checkbox'>
													<Checkbox
														color='primary'
														checked={isItemSelected}
														onChange={(e) => handleSelect(e, row.id)}
														inputProps={{
															'aria-labelledby': labelId,
														}}
													/>
												</TableCell>
												<TableCell style={{ width: 160 }} align=''>
													<IconButton
														aria-label='edit'
														onDoubleClick={() => {
															setEditId(row.id);
															setEditItem(row);
														}}
														color='primary'>
														<Edit />
													</IconButton>
													<IconButton
														aria-label='edit'
														onContextMenu={(e) => {
															e.preventDefault();
															console.log(row.id);
															setShowDeletePopup(true);
															setDeleteId(row.id);
															setAnchorElForDeletePopup(e.currentTarget);
														}}
														color='error'>
														<DeleteForever />
													</IconButton>
													<Popover
														id={row.id}
														open={showDeletePopup}
														anchorEl={anchorElForDeletePopup}
														onClose={handleCloseDeletePopup}
														anchorOrigin={{
															vertical: 'bottom',
															horizontal: 'left',
														}}>
														<Typography sx={{ p: 2 }}>
															Do you want to delete this record?
														</Typography>
														<Box
															sx={{
																width: '100%',
																display: 'flex',
																justifyContent: 'flex-end',
																alignItems: 'center',
																padding: '0 0.25em 0.25em 0.25em',
															}}>
															<Button
																color='error'
																onClick={() => {
																	const newState = formState.filter(
																		(item) => item.id !== deleteId
																	);
																	setFormState(newState);
																	handleCloseDeletePopup();
																}}>
																Delete
															</Button>
															<Button
																color='primary'
																onClick={handleCloseDeletePopup}>
																Cancel
															</Button>
														</Box>
													</Popover>
												</TableCell>
												<TableCell
													component='th'
													id={labelId}
													scope='row'
													padding='none'>
													{row.firstName}
												</TableCell>
												<TableCell align=''>{row.lastName}</TableCell>
												<TableCell align=''>{row.gender}</TableCell>
												<TableCell align=''>{row.email}</TableCell>
												<TableCell align=''>{row.phone}</TableCell>
											</TableRow>
										) : (
											<TableRow key={row.firstName + index}>
												<TableCell padding='checkbox'>
													<Checkbox
														color='primary'
														checked={isItemSelected}
														inputProps={{
															'aria-labelledby': labelId,
														}}
														disabled
													/>
												</TableCell>
												<TableCell style={{ width: 50 }} align=''>
													<IconButton
														aria-label='edit'
														onClick={() => {
															setEditId('');
															updateFormState(row);
														}}
														color='success'>
														<Check />
													</IconButton>
												</TableCell>
												<TableCell component='th' scope='row'>
													<Input
														type='text'
														value={editItem.firstName}
														onChange={(e) =>
															setEditItem({
																...editItem,
																firstName: e.target.value,
															})
														}
													/>
												</TableCell>
												<TableCell style={{ width: 160 }} align=''>
													<Input
														type='text'
														value={editItem.lastName}
														onChange={(e) =>
															setEditItem({
																...editItem,
																lastName: e.target.value,
															})
														}
													/>
												</TableCell>
												<TableCell style={{ width: 160 }} align=''>
													<FormControl variant='standard'>
														<Select
															labelId='simple-select-label'
															id='demo-simple-select'
															value={editItem.gender}
															onChange={(e) =>
																setEditItem({
																	...editItem,
																	gender: e.target.value,
																})
															}>
															{genders.map((gender) => (
																<MenuItem
																	key={gender.value}
																	value={gender.value}>
																	{gender.name}
																</MenuItem>
															))}
														</Select>
													</FormControl>
												</TableCell>
												<TableCell style={{ width: 160 }} align=''>
													<Input
														type='text'
														value={editItem.email}
														onChange={(e) =>
															setEditItem({
																...editItem,
																email: e.target.value,
															})
														}
													/>
												</TableCell>
												<TableCell style={{ width: 160 }} align=''>
													<Input
														type='text'
														value={editItem.phone}
														onChange={(e) =>
															setEditItem({
																...editItem,
																phone: e.target.value,
															})
														}
													/>
												</TableCell>
											</TableRow>
										);
									})}

								{emptyRows > 0 && (
									<TableRow style={{ height: 53 * emptyRows }}>
										<TableCell colSpan={6} />
									</TableRow>
								)}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
						component='div'
						colSpan={5}
						count={filterBySearch(formState).length}
						rowsPerPage={rowsPerPage}
						page={page}
						SelectProps={{
							inputProps: {
								'aria-label': 'rows per page',
							},
							// native: true,
						}}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
						ActionsComponent={TablePaginationActions}
					/>
				</Paper>
			</CenterBox>
		</PageContainer>
	);
};

export default WebTable;
