import { atom } from "recoil";

const predefinedData = [
	{
		id: "1",
		firstName: "Stuart",
		lastName: "Russell",
		email: "stuart.russell@at.in",
		gender: "Male",
		phone: "9155056759",
	},
	{
		id: "2",
		firstName: "Natalie",
		lastName: "Perry",
		email: "natalie.perry@at.in",
		gender: "Female",
		phone: "8720633581",
	},
	{
		id: "3",
		firstName: "Sam",
		lastName: "Spencer",
		email: "sam.spencer@at.in",
		gender: "Male",
		phone: "7839145861",
	},
	{
		id: "4",
		firstName: "Aldus",
		lastName: "Harper",
		email: "aldus.harper@at.in",
		gender: "Male",
		phone: "7238332884",
	},
	{
		id: "5",
		firstName: "Max",
		lastName: "West",
		email: "max.west@at.in",
		gender: "Male",
		phone: "6869034641",
	},
	{
		id: "6",
		firstName: "Brad",
		lastName: "Cunningham",
		email: "brad.cunningham@at.in",
		gender: "Male",
		phone: "8673059015",
	},
	{
		id: "7",
		firstName: "Dale",
		lastName: "Rogers",
		email: "dale.rogers@at.in",
		gender: "Male",
		phone: "6840684025",
	},
	{
		id: "8",
		firstName: "David",
		lastName: "Miller",
		email: "david.miller@at.in",
		gender: "Male",
		phone: "6899733368",
	},
	{
		id: "9",
		firstName: "Miley",
		lastName: "Evans",
		email: "miley.evans@at.in",
		gender: "Female",
		phone: "6558063869",
	},
	{
		id: "10",
		firstName: "Ashton",
		lastName: "Carroll",
		email: "ashton.carroll@at.in",
		gender: "Male",
		phone: "7994043583",
	},
	{
		id: "11",
		firstName: "Adele",
		lastName: "Harris",
		email: "adele.harris@at.in",
		gender: "Female",
		phone: "6114896099",
	},
	{
		id: "12",
		firstName: "Vanessa",
		lastName: "Carter",
		email: "vanessa.carter@at.in",
		gender: "Female",
		phone: "9876543210",
	},
	{
		id: "13",
		firstName: "Michelle",
		lastName: "Turner",
		email: "michelle.turner@at.in",
		gender: "Female",
		phone: "6867916884",
	},
	{
		id: "14",
		firstName: "Byron",
		lastName: "Anderson",
		email: "byron.anderson@at.in",
		gender: "Male",
		phone: "5853860645",
	},
	{
		id: "15",
		firstName: "Sarah",
		lastName: "Crawford",
		email: "sarah.crawford@at.in",
		gender: "Female",
		phone: "4252109810",
	},
	{
		id: "16",
		firstName: "Michael",
		lastName: "Riley",
		email: "michael.riley@at.in",
		gender: "Male",
		phone: "4572685956",
	},
	{
		id: "17",
		firstName: "Fenton",
		lastName: "Cole",
		email: "fenton.cole@at.in",
		gender: "Male",
		phone: "4825723189",
	},
	{
		id: "18",
		firstName: "Michelle",
		lastName: "Ferguson",
		email: "michelle.ferguson@at.in",
		gender: "Female",
		phone: "5853860645",
	},
	{
		id: "19",
		firstName: "Kate",
		lastName: "Walker",
		email: "kate.walker@at.in",
		gender: "Female",
		phone: "5994808349",
	},
	{
		id: "20",
		firstName: "Robert",
		lastName: "Turner",
		email: "robert.turner@at.in",
		gender: "Male",
		phone: "7367915665",
	},
	{
		id: "21",
		firstName: "Henry",
		lastName: "Hamilton",
		email: "henry.hamilton@at.in",
		gender: "Male",
		phone: "2529048711",
	},
	{
		id: "22",
		firstName: "Adison",
		lastName: "Armstrong",
		email: "adison.armstrong@at.in",
		gender: "Male",
		phone: "7427911317",
	},
	{
		id: "23",
		firstName: "Edgar",
		lastName: "Hamilton",
		email: "edgar.hamilton@at.in",
		gender: "Male",
		phone: "3336288139",
	},
	{
		id: "24",
		firstName: "Chester",
		lastName: "Perry",
		email: "chester.perry@at.in",
		gender: "Male",
		phone: "7002080360",
	},
	{
		id: "25",
		firstName: "Dale",
		lastName: "Farrell",
		email: "dale.farrell@at.in",
		gender: "Male",
		phone: "1201110856",
	},
	{
		id: "26",
		firstName: "Audrey",
		lastName: "Gibson",
		email: "audrey.gibson@at.in",
		gender: "Female",
		phone: "2569275010",
	},
	{
		id: "27",
		firstName: "Luke",
		lastName: "Thomas",
		email: "luke.thomas@at.in",
		gender: "Male",
		phone: "3470449807",
	},
	{
		id: "28",
		firstName: "William",
		lastName: "Brown",
		email: "william.brown@at.in",
		gender: "Male",
		phone: "2826980317",
	},
	{
		id: "29",
		firstName: "Sarah",
		lastName: "Watson",
		email: "sarah.watson@at.in",
		gender: "Female",
		phone: "3465518251",
	},
	{
		id: "30",
		firstName: "Myra",
		lastName: "Rogers",
		email: "myra.rogers@at.in",
		gender: "Female",
		phone: "6388931433",
	},
];

const formAtom = atom({
	key: "formAtom",
	default: predefinedData,
});
export default formAtom;
