import styled from 'styled-components';
import React from 'react';

const DropdownDiv = styled.div`
	position: absolute;
	top: 56px;
	width: 250px;
	transform: translateX(-45%);
	background-color: #fafafa;
	color: black;
	border: 1px solid;
	border-color: gray;
	border-radius: 4px;
	padding: 0.5rem;
	// overflow: hidden;
	z-index: 12;
`;

const DropdownLeftIcon = styled.span`
	// border: 1px solid gray;
`;

const DropdownRightIcon = styled.span`
	margin-left: auto;
`;

const DropdownTitle = styled.span`
	padding: 0.5rem;
`;

const Item = styled(({ component, ...props }) =>
	React.cloneElement(component, props)
)`
	height: 40px;
	display: flex;
	align-items: center;
	border-radius: 4px;
	transition: background 0.3s;
	padding: 0.5rem;
	text-decoration: none;
	color: black;

	&:hover {
		background: #deedff;
	}
`;

export const DropdownItem = ({
	leftIcon,
	children,
	rightIcon,
	component = <span />,
	style,
}) => {
	return (
		<Item component={component} style={{ ...style }}>
			{leftIcon && <DropdownLeftIcon>{leftIcon}</DropdownLeftIcon>}
			{children}
			{rightIcon && <DropdownRightIcon>{rightIcon}</DropdownRightIcon>}
		</Item>
	);
};

const Dropdown = (props) => {
	const { title, children, style = {} } = props;
	return (
		<DropdownDiv style={{ ...style }}>
			{title && <DropdownTitle>{title}</DropdownTitle>}
			{children}
		</DropdownDiv>
	);
};

export default Dropdown;
