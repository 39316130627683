import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const GoogleAd = (props) => {
	// let [googleInit, setGoogleInit] = useState(null);

	useEffect(() => {
		// const { timeout } = props;
		// setGoogleInit(
		// 	setTimeout(() => {
		// 		if (typeof window !== 'undefined')
		// 			(window.adsbygoogle = window.adsbygoogle || []).push({});
		// 		console.log('entered', window.adsbygoogle);
		// 	}, timeout)
		// );

		// return () => {
		// 	if (googleInit) clearTimeout(googleInit);
		// };
		const pushAd = () => {
			try {
				const adsbygoogle = window.adsbygoogle;
				adsbygoogle.push({});
			} catch (e) {
				console.error(e);
			}
		};

		let interval = setInterval(() => {
			// Check if Adsense script is loaded every 300ms
			if (window.adsbygoogle.loaded) {
				pushAd();
				// clear the interval once the ad is pushed so that function isn't called indefinitely
				clearInterval(interval);
			}
		}, 300);

		return () => {
			clearInterval(interval);
		};
	}, []);

	const { slot, googleAdId, style, format } = props;
	return (
		// <div className={classNames} style={style}>
		<ins
			className='adsbygoogle'
			style={
				{
					...style,
				} || {
					display: 'block',
					textAlign: 'center',
					width: '100%',
					height: '100%',
				}
			}
			data-ad-client={googleAdId}
			data-ad-slot={slot}
			data-ad-format={format || 'auto'}
			data-full-width-responsive='true'></ins>
		// </div>
	);
};

GoogleAd.propTypes = {
	classNames: PropTypes.string,
	slot: PropTypes.string,
	timeout: PropTypes.number,
	googleAdId: PropTypes.string,
};
GoogleAd.defaultProps = {
	classNames: '',
	timeout: 200,
	style: { display: 'inline-block', width: '100%', height: '100%' },
};
export default GoogleAd;
