import { Box, Typography } from "@mui/material";
import React from "react";
import CenterBox from "../../components/CenterBox";
import PageCard from "../../components/PageCard";
import PageContainer from "../../components/PageContainer";

export function ShadowRoot(props) {
	const attachShadow = (host) => {
		if (host == null) {
			return;
		}
		host.attachShadow({ mode: "open" });
		host.shadowRoot.innerHTML = host.innerHTML;
		host.innerHTML = "";
	};

	return (
		<Box
			ref={attachShadow}
			id="shadow-root"
			sx={{
				display: "block",
			}}
		>
			<span
				id="shadow-element"
				style={{
					display: "flex",
					width: "100%",
					margin: "15px",
					padding: "15px",
					background: "#00529cff",
					borderRadius: "10px",
					color: "white",
				}}
			>
				Shadow Element
			</span>
			{props.children}
		</Box>
	);
}

function ShadowDom({ children }) {
	return (
		<PageContainer>
			<CenterBox>
				<PageCard sx={{ display: "flex" }}>
					<Box sx={{ padding: "10px", flex: 1 }}>
						<Typography variant="h5" textAlign="center">
							Practice your Shadow DOM testing here!
						</Typography>
						<Box
							sx={{
								width: "100%",
								height: "100%",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}
						>
							<ShadowRoot>
								<inner-shadow-dom id="inner-shadow-dom"></inner-shadow-dom>
							</ShadowRoot>
						</Box>
					</Box>
				</PageCard>
			</CenterBox>
		</PageContainer>
	);
}

export default ShadowDom;

customElements.define(
	"inner-shadow-dom",
	class extends HTMLElement {
		constructor() {
			super();
			this.attachShadow({ mode: "open" });
			this.shadowRoot.innerHTML = `
      <style>
        /* Inner Shadow DOM component styles go here */
        div {
          margin-top: 20px;
        }
        div span {
          display: flex;
          width: 100%;
          margin: 15px;
          padding: 15px;
          background-color: #db214cff;
          border-radius: 10px;
          color: white;
        }
        
      </style>
      <div id="nested-shadow-root">
        <span id="nested-shadow-element">Nested Shadow Element</span>
        <nested-shadow-dom id="nested-shadow-dom"></nested-shadow-dom>
      </div>
    `;
		}
	}
);
customElements.define(
	"nested-shadow-dom",
	class extends HTMLElement {
		constructor() {
			super();
			this.attachShadow({ mode: "open" });
			this.shadowRoot.innerHTML = `
      <style>
        /* Inner Shadow DOM component styles go here */
        div {
          margin-top: 20px;
        }
        div span {
          display: flex;
          width: 100%;
          margin: 15px;
          padding: 15px;
          background-color: #15354cff;
          border-radius: 10px;
          color: white;
        }
        
      </style>
      <div id="multi-nested-shadow-root">
        <span id="multi-nested-shadow-element">Multi-nested Shadow Element</span>
      </div>
    `;
		}
	}
);
