import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Box, Stack, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import CenterBox from '../../components/CenterBox';
import PageCard from '../../components/PageCard';
import PageContainer from '../../components/PageContainer';
import { MobileDatePicker } from '@mui/x-date-pickers';

const Datepickers = () => {
	const [value, setValue] = useState(dayjs());

	return (
		<PageContainer>
			<CenterBox>
				<PageCard>
					<Typography variant='h4' textAlign={'center'} paddingTop={2}>DatePickers</Typography>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<Stack
							direction={'row'}
							spacing={3}
							padding={10}
							justifyContent='space-around'>
							<DatePicker
								label='Disabled Input'
								value={value}
								onChange={(newValue) => setValue(newValue)}
								renderInput={({ inputRef, inputProps, InputProps, label }) => (
									<Box sx={{ display: 'flex', alignItems: 'center' }}>
										<TextField
											ref={inputRef}
											{...inputProps}
											label={label}
											disabled
										/>
										{InputProps.endAdornment}
									</Box>
								)}
							/>
							<DatePicker
								label='Type or Select'
								value={value}
								onChange={(newValue) => setValue(newValue)}
								renderInput={(params) => <TextField {...params} />}
							/>
							<MobileDatePicker
								label='Popover for Mobile'
								value={value}
								onChange={(newValue) => {
									setValue(newValue);
								}}
								renderInput={(params) => <TextField {...params} />}
							/>
						</Stack>
					</LocalizationProvider>
				</PageCard>
			</CenterBox>
		</PageContainer>
	);
};

export default Datepickers;
