import { Box } from "@mui/material";
import styled from "styled-components";

const CenterBox = styled(Box)({
	width: "100%",
	height: "100%",
	display: "flex",
	flexDirection: (props) => (props.row ? "row" : props.column && "column"),
	alignItems: "center",
	justifyContent: "center",
});

export default CenterBox;
