import { Box, Paper, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import CenterBox from '../../components/CenterBox';
import PageCard from '../../components/PageCard';
import PageContainer from '../../components/PageContainer';

const DynamicData = () => {
	const [loading, setLoading] = useState(false);
	const [apiData, setApiData] = useState({});

	const getRandomData = async () => {
		const url = 'https://api.randomuser.me/?nat=us';
		setLoading(true);
		await fetch(url, {
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
		})
			.then((res) => res.json())
			.then((response) => {
				setApiData(response.results[0]);
				setLoading(false);
			});
	};

	useEffect(() => {
		getRandomData();
	}, []);

	return (
		<PageContainer>
			<CenterBox>
				<PageCard>
					<Typography variant='h5' textAlign={'center'} paddingTop={1}>
						Loading the data Dynamically
					</Typography>
					<CenterBox>
						<Box
							component={Paper}
							elevation={1}
							sx={{ maxWidth: '70%', maxHeight: '80%', height: '80%' }}>
							<Box
								sx={{
									color: '#333',
									backgroundColor: '#f5f5f5',
									borderColor: '#ddd',
									padding: '1rem',
								}}>
								<Typography variant='body2'>Get Random User</Typography>
							</Box>
							<Box sx={{ padding: '1rem' }}>
								<Typography variant='body2' padding={2}>
									Clicking on 'Get Dynamic Data' button will get new data every
									time with Image, First name and Last name
								</Typography>
								{loading ? (
									<CenterBox sx={{ height: '14rem' }}>
										<img
											src={require('../../assets/spinner.gif')}
											style={{ maxHeight: '100%' }}
											alt='spinner'
										/>
									</CenterBox>
								) : (
									<Stack
										direction={'column'}
										alignItems='center'
										height={'14rem'}>
										<Box sx={{ height: '90%' }}>
											<img
												src={`${apiData?.picture?.large}`}
												style={{ height: '100%' }}
												alt='profile'
											/>
										</Box>
										<Box>
											<Typography variant='body1'>
												First Name: <b>{apiData?.name?.first}</b>
											</Typography>
											<Typography variant='body1'>
												Last Name: <b>{apiData?.name?.last}</b>
											</Typography>
										</Box>
									</Stack>
								)}

								<CenterBox sx={{ marginTop: '1rem' }}>
									<LoadingButton
										variant='outlined'
										onClick={getRandomData}
										loading={loading}>
										Get Dynamic Data
									</LoadingButton>
								</CenterBox>
							</Box>
						</Box>
					</CenterBox>
				</PageCard>
			</CenterBox>
		</PageContainer>
	);
};

export default DynamicData;
