import {
	Box,
	Grid,
	Input,
	Paper,
	Slider,
	Stack,
	styled,
	Typography,
} from '@mui/material';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import CenterBox from '../../components/CenterBox';
import PageCard from '../../components/PageCard';
import PageContainer from '../../components/PageContainer';

const CustomizedSlider = styled(Slider)((props) => ({
	height: props.orientation === 'vertical' ? '' : 8,
	width: props.orientation === 'vertical' ? 8 : '',
	'& .MuiSlider-track': {
		border: 'none',
		height: props.orientation === 'vertical' ? '' : 8,
	},
	'& .MuiSlider-thumb': {
		height: 24,
		width: 24,
		backgroundColor: '#fff',
		border: '2px solid currentColor',
		'&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
			boxShadow: 'inherit',
		},
		'&:before': {
			display: 'none',
		},
	},
}));

const CustomValueLabelSlider = styled(CustomizedSlider)((props) => ({
	'& .MuiSlider-valueLabel': {
		lineHeight: 1.2,
		fontSize: 12,
		background: 'unset',
		padding: 0,
		width: 32,
		height: 32,
		borderRadius:
			props.orientation === 'vertical' ? '50% 50% 0 50%' : '50% 50% 50% 0',
		backgroundColor: '#007cc3',
		transformOrigin: 'bottom left',
		transform:
			props.orientation === 'vertical'
				? 'translate(50%, -75%) rotate(-45deg) scale(0)'
				: 'translate(50%, -100%) rotate(-45deg) scale(0)',
		'&:before': { display: 'none' },
		'&.MuiSlider-valueLabelOpen': {
			transform:
				props.orientation === 'vertical'
					? 'translate(50%, -75%) rotate(-45deg) scale(1)'
					: 'translate(50%, -100%) rotate(-45deg) scale(1)',
		},
		'& > *': {
			transform: 'rotate(45deg)',
		},
	},
}));

const Sliders = () => {
	// EMI Amount = [P x R x (1+R)^N]/[(1+R)^N-1]
	// EMI = 200000* 0.0129 * (1+ 0.0129)^24 = Rs 9,745 / [(1+ 0.0129)^24 ]-1
	const [principal, setPrincipal] = useState(500000);
	const [roi, setRoi] = useState(5);
	const [tenure, setTenure] = useState(2);
	const [result, setResult] = useState();

	useEffect(() => {
		if (principal && tenure && roi) {
			// const temp =
			// 	((((principal * roi) / 100 / 12) * (1 + roi / 100 / 12)) ^
			// 		(tenure * 12)) /
			// 	((1 + roi / 100 / 12) ^ (tenure * 12 - 1));
			// EMI= Rs 10,00,000 * 0.006 * (1 + 0.006)120 / ((1 + 0.006)120 - 1) = Rs 1F1,714.

			const interest = roi / 12 / 100;
			const tenureInMonths = tenure * 12;
			const total = principal * interest * (1 + interest) ** tenureInMonths;
			const den = (1 + interest) ** tenureInMonths - 1;
			const temp = total / den;
			setResult(temp);
		}
	}, [principal, tenure, roi]);

	const marks = [
		{
			value: 0,
			label: '0',
		},
		{
			value: 500000,
			label: '5L',
		},
		{
			value: 1000000,
			label: '10L',
		},
		{
			value: 1500000,
			label: '15L',
		},
		{
			value: 2000000,
			label: '20L',
		},
		{
			value: 2500000,
			label: '25L',
		},
		{
			value: 3000000,
			label: '30L',
		},
	];

	function valuetext(value) {
		return `${value / 100000}L`;
	}

	function preventHorizontalKeyboardNavigation(event) {
		if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
			event.preventDefault();
		}
	}

	return (
		<PageContainer>
			<CenterBox>
				<PageCard>
					<Typography variant='h5' textAlign={'center'}>
						Personal Loan Calculator (Sliders)
					</Typography>
					<Grid container xs={12}>
						<Grid item xs={9}>
							<Stack direction={'column'} gap={2} sx={{ margin: '3rem' }}>
								<Box>
									<Typography id='principal-slider' gutterBottom>
										Principal Loan Amount
									</Typography>
									<CustomizedSlider
										aria-label='principal amount'
										getAriaValueText={valuetext}
										step={50000}
										value={principal}
										onChange={(_, newPrincipal) => setPrincipal(newPrincipal)}
										marks={marks}
										min={0}
										max={3000000}
										valueLabelDisplay='auto'
										aria-labelledby='principal-slider'
									/>
								</Box>
								<Box>
									<Typography id='roi-slider' gutterBottom>
										Rate of Interest
									</Typography>
									<Grid container spacing={2} alignItems='center'>
										<Grid item xs={9}>
											<CustomValueLabelSlider
												value={typeof roi === 'number' ? roi : 0}
												onChange={(_, newRoi) => setRoi(newRoi)}
												aria-labelledby='roi-slider'
												// marks
												valueLabelDisplay='auto'
											/>
										</Grid>
										<Grid item>
											<Input
												value={roi}
												size='small'
												onChange={(e) => {
													// eslint-disable-next-line no-mixed-operators
													if (e.target.value >= 0 && e.target.value < 101) {
														setRoi(
															e.target.value === ''
																? ''
																: Number(e.target.value)
														);
													}
												}}
												inputProps={{
													step: 1,
													min: 0,
													max: 100,
													type: 'number',
													'aria-labelledby': 'roi-slider',
												}}
											/>
										</Grid>
									</Grid>
								</Box>

								<Box>
									{result && (
										<>
											<Typography>Result</Typography>
											<Box
												sx={{
													width: '100%',
													display: 'flex',
													justifyContent: 'center',
												}}>
												<Box
													component={Paper}
													width={200}
													textAlign={'center'}
													padding={2}
													sx={{ backgroundColor: '#eeeeee' }}>
													<Typography
														sx={{ fontSize: '12px', color: '#c0c0c0' }}>
														Monthly EMI
													</Typography>
													<Typography
														sx={{
															fontSize: '18px',
															padding: '1rem 2rem',
															fontWeight: '600',
														}}>
														{Math.round(result)}
													</Typography>
												</Box>
											</Box>
										</>
									)}
								</Box>
							</Stack>
						</Grid>
						<Grid item xs={3}>
							<Box sx={{ height: 300 }}>
								<Typography id='tenure-slider' gutterBottom>
									Tenure (in Years)
								</Typography>
								<Stack
									direction={'row'}
									height={'90%'}
									justifyContent={'center'}>
									<CustomValueLabelSlider
										sx={{
											'& input[type="range"]': {
												WebkitAppearance: 'slider-vertical',
											},
										}}
										orientation='vertical'
										value={typeof tenure === 'number' ? tenure : 0}
										onChange={(_, newTenure) => setTenure(newTenure)}
										aria-label='Tenure'
										size='medium'
										valueLabelDisplay='auto'
										onKeyDown={preventHorizontalKeyboardNavigation}
										aria-labelledby='tenure-slider'
										// marks
										min={0}
										max={20}
									/>
									<Box sx={{ display: 'flex', alignItems: 'center' }}>
										<Input
											value={tenure}
											size='small'
											onChange={(e) => {
												setTenure(
													e.target.value === '' ? '' : Number(e.target.value)
												);
											}}
											inputProps={{
												step: 1,
												min: 0,
												max: 10,
												type: 'number',
												'aria-labelledby': 'tenure-slider',
											}}
										/>
									</Box>
								</Stack>
							</Box>
						</Grid>
					</Grid>
				</PageCard>
			</CenterBox>
		</PageContainer>
	);
};

export default Sliders;
