import { Box, Typography } from "@mui/material";
import React, { useEffect } from "react";
import styled from "styled-components";
import CenterBox from "../../components/CenterBox";
import PageCard from "../../components/PageCard";
import PageContainer from "../../components/PageContainer";

const HiddenDiv = styled(Box)({
	display: "none",
});

function Text() {
	useEffect(() => {
		const div = document.getElementById("text-div");
		console.log(div.innerText, div.innerHTML, div.textContent);
	});
	return (
		<PageContainer>
			<CenterBox>
				<PageCard>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}
					>
						<Typography variant="h5" textAlign="center">
							Work with Text
						</Typography>
						<Typography
							variant="caption"
							sx={{ textAlign: "center", mt: 1, fontSize: '15px' }}
						>
							Here's a dummy text with id{" "}
							text-div that has innerText,
							innerHTML and textContent to test out.
						</Typography>
						<div id="text-div">
							<Typography
								variant="h2"
								sx={{ textAlign: "center", mt: 2 }}
							>
								Practice your automation testing here.
							</Typography>
							<HiddenDiv>
								I'm hidden! Hope you can't see me.
							</HiddenDiv>
						</div>
					</Box>
				</PageCard>
			</CenterBox>
		</PageContainer>
	);
}

export default Text;
