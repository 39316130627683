import {
	Box,
	Button,
	Checkbox,
	Chip,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormLabel,
	Grid,
	ImageList,
	ImageListItem,
	InputLabel,
	ListItemText,
	MenuItem,
	NativeSelect,
	OutlinedInput,
	Radio,
	RadioGroup,
	Select,
	TextField,
	Typography,
} from "@mui/material";
import { useRecoilState } from "recoil";
// import { useTheme } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CenterBox from "../../components/CenterBox";
import PageCard from "../../components/PageCard";
import PageContainer from "../../components/PageContainer";
import Logo from "../../assets/original.png";
import formAtom from "../../recoil/formAtom";
import { useNavigate } from "react-router";

export const Form = styled(Box).attrs({ component: "form" })`
	margin: 1rem;
	width: 75%;
`;

export const FormPageCard = styled(PageCard)`
	display: flex;
	justify-content: center;
`;

const FileInput = styled.input.attrs({ type: "file" })`
	width: 80% !important;
	align-self: center !important;
	transform: translateX(10px);
`;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

const Register = () => {
	// require("../../assets/original.png")
	const languageList = [
		"Arabic",
		"Bulgarian",
		"Catalan",
		"Croatian",
		"Czech",
		"Danish",
		"Dutch",
		"English",
		"Estopian",
		"Filipino",
		"Finnish",
		"French",
		"German",
		"Greek",
		"Hebrew",
		"Hindi",
		"Hungarian",
		"Icelandic",
		"Indonesian",
		"Italian",
		"Japanese",
		"Korean",
		"Latvian",
		"Lithuanian",
		"Malay",
		"Malayalam",
		"Norwegian",
		"Persian",
		"Polish",
		"Portuguese",
		"Romanian",
		"Russian",
		"Serbian",
		"Slovak",
		"Slovenian",
		"Spanish",
		"Swedish",
		"Tamil",
		"Telugu",
		"Thai",
		"Turkish",
		"Ukranian",
		"Urdu",
		"Vietnamese",
	];
	const skillsList = [
		"Adobe Indesign",
		"Adobe Photoshop",
		"Analytics",
		"Android",
		"APIs",
	];
	const defaultDaysList = [
		1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
		22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
	];
	const [daysList, setDaysList] = useState(defaultDaysList);
	const monthsList = [
		{ name: "January", value: 1 },
		{ name: "February", value: 2 },
		{ name: "March", value: 3 },
		{ name: "April", value: 4 },
		{ name: "May", value: 5 },
		{ name: "June", value: 6 },
		{ name: "July", value: 7 },
		{ name: "August", value: 8 },
		{ name: "September", value: 9 },
		{ name: "October", value: 10 },
		{ name: "November", value: 11 },
		{ name: "December", value: 12 },
	];
	const yearsList = [
		1985, 1986, 1987, 1988, 1989, 1990, 1991, 1992, 1993, 1994, 1995, 1996,
		1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008,
		2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020,
		2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032,
		2033, 2034, 2035, 2036, 2037, 2038, 2039, 2040, 2041, 2042, 2043, 2044,
		2045,
	];
	const defaultCountriesList = ["America", "India", "Srilanka"];
	const [countriesList, setCountriesList] = useState(defaultCountriesList);
	const [country, setCountry] = useState("");
	const [day, setDay] = useState("");
	const [month, setMonth] = useState("");
	const [year, setYear] = useState("");
	const [countrySearch, setCountrySearch] = useState("");
	const [languages, setLanguages] = React.useState([]);
	const [skill, setSkill] = React.useState("");
	const [imageSelected, setImageSelected] = useState(Logo);
	const [formState, setFormState] = useRecoilState(formAtom);
	const [countryMenuOpen, setCountryMenuOpen] = useState(false);
	const navigate = useNavigate();

	//* Form Values
	const [firstName, setFirstName] = useState("");
	const handleChangeFirstName = (e) => {
		setFirstName(e.target.value);
	};
	const [lastName, setLastName] = useState("");
	const handleChangeLastName = (e) => {
		setLastName(e.target.value);
	};
	const [address, setAddress] = useState("");
	const handleChangeAddress = (e) => {
		setAddress(e.target.value);
	};
	const [email, setEmail] = useState("");
	const handleChangeEmail = (e) => {
		setEmail(e.target.value);
	};
	const [phone, setPhone] = useState("");
	const handleChangePhone = (e) => {
		setPhone(e.target.value);
	};
	const [gender, setGender] = useState("");
	const handleChangeGender = (e) => {
		setGender(e.target.value);
	};
	const [interests, setInterests] = useState({ manual_testing: true });
	const handleChangeInterests = (e) => {
		console.log(e.target.name, e.target.value);
		setInterests({
			...interests,
			[e.target.name]: e.target.value,
		});
	};
	const [password, setPassword] = useState([]);
	const handleChangePassword = (e) => {
		setPassword(e.target.value);
	};
	const [confirmPassword, setConfirmPassword] = useState("");
	const handleChangeConfirmPassword = (e) => {
		setConfirmPassword(e.target.value);
	};

	useEffect(() => {
		if (countrySearch) {
			setCountriesList(
				defaultCountriesList.filter((c) => c.includes(countrySearch))
			);
		} else {
			setCountriesList(defaultCountriesList);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [countrySearch]);

	useEffect(() => {
		if (Number(month) === 2) {
			setDaysList(defaultDaysList.filter((day) => day <= 28));
		} else if (
			((month > 7 && month % 2 === 1) || (month < 7 && month % 2 === 0)) &&
			Number(month) !== 2
		) {
			setDaysList((prevDays) => prevDays.filter((day) => day !== 31));
		} else {
			setDaysList(defaultDaysList);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [month]);

	useEffect(() => {
		if (day) {
			if (daysList.indexOf(day) === -1) {
				setDay(1);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [month]);

	const handleChangeLanguage = (event) => {
		console.log(event.target.value);
		const {
			target: { value },
		} = event;
		setLanguages(
			// On autofill we get a stringified value.
			typeof value === "string" ? value.split(",") : value
		);
	};

	const handleClearAll = () => {};

	const handleSubmit = (e) => {
		e.preventDefault();
		setFormState([
			...formState,
			{
				id: formState.length + 1,
				firstName,
				lastName,
				email,
				phone,
				gender,
				skill,
				country,
			},
		]);
		navigate("/table");
	};

	return (
		<PageContainer maxWidth="xl">
			<CenterBox>
				<FormPageCard>
					<Form onSubmit={handleSubmit}>
						<Typography
							variant="h5"
							textAlign={"center"}
							fontWeight={"500"}
							padding={"0 0 1em 0"}
						>
							Register Form
						</Typography>
						<Box
							component="div"
							style={{
								paddingBottom: "2rem",
							}}
						>
							<Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
								<Grid
									container
									item
									spacing={2}
									xs={7}
									columns={{ xs: 4, sm: 8, md: 12 }}
								>
									<Grid
										container
										item
										spacing={2}
										alignItems={"center"}
										xs={12}
									>
										{
											// <Grid item xs={2}>
											// 	<InputLabel>Full Name</InputLabel>
											// </Grid>
										}
										<Grid item xs={6}>
											<TextField
												value={firstName}
												label="First Name"
												name="firstName"
												type="text"
												fullWidth
												placeholder="First Name"
												onChange={handleChangeFirstName}
												variant="outlined"
											/>
											{
												// errors.email && <Error>{errors.email}</Error>
											}
										</Grid>
										<Grid item xs={6}>
											<TextField
												value={lastName}
												label="Last Name"
												name="lastName"
												type="text"
												fullWidth
												placeholder="Last Name"
												onChange={handleChangeLastName}
												variant="outlined"
											/>
											{
												// errors.email && <Error>{errors.email}</Error>
											}
										</Grid>
									</Grid>
									<Grid
										container
										item
										spacing={2}
										alignItems={"center"}
										xs={12}
									>
										{
											// <Grid item xs={2}>
											// 	<InputLabel>Address</InputLabel>
											// </Grid>
										}
										<Grid item xs={12}>
											<TextField
												value={address}
												label="Address"
												name="address"
												type="text"
												fullWidth
												placeholder=""
												multiline
												minRows={"2"}
												maxRows={"3"}
												onChange={handleChangeAddress}
												variant="outlined"
											/>
											{
												// errors.email && <Error>{errors.email}</Error>
											}
										</Grid>
									</Grid>
									<Grid
										container
										item
										spacing={2}
										alignItems={"center"}
										xs={12}
									>
										<Grid item xs={12}>
											<TextField
												value={email}
												label="Email Address"
												name="email"
												type="text"
												fullWidth
												placeholder="Email address"
												onChange={handleChangeEmail}
												variant="outlined"
											/>
										</Grid>
									</Grid>
								</Grid>
								<Grid
									container
									item
									spacing={2}
									alignItems="center"
									xs={5}
									justifyItems={"center"}
									columns={{ xs: 4, sm: 8, md: 12 }}
								>
									<Grid item xs={12}>
										<CenterBox
											style={{
												flexDirection: "column",
												alignContent: "center",
											}}
										>
											<Typography variant="label">Selected Image</Typography>
											<ImageList sx={{ width: 150, height: 150 }} cols={1}>
												<ImageListItem sx={{ width: 150, height: 150 }}>
													{
														// <img src={`${imageSelected}?w=100&h=100&fit=crop&auto=format`} alt="selected pic" style={{ objectFit: "contain" }} />
														<img
															src={imageSelected}
															alt="selected pic"
															style={{
																objectFit: "contain",
																width: "150px",
																height: "150px",
															}}
														/>
													}
												</ImageListItem>
											</ImageList>
											<FileInput
												className="form-control"
												onChange={(e) => {
													console.log(e);
													let reader = new FileReader();
													reader.onload = (e) => {
														console.log(e);
														setImageSelected(e.target.result);
													};
													reader.readAsDataURL(e.target.files[0]);
												}}
											/>
										</CenterBox>
									</Grid>
								</Grid>
								<Grid
									item
									container
									spacing={2}
									xs={7}
									columns={{ xs: 4, sm: 8, md: 12 }}
								>
									<Grid item xs={12}>
										<TextField
											value={phone}
											label="Phone"
											name="phone"
											type="tel"
											fullWidth
											placeholder="Phone number"
											onChange={handleChangePhone}
											variant="outlined"
										/>
									</Grid>
									<Grid item xs={12}>
										<FormControl>
											<FormLabel id="demo-radio-buttons-group-label">
												Gender
											</FormLabel>
											<RadioGroup
												value={gender}
												aria-labelledby="demo-radio-buttons-group-label"
												defaultValue="Female"
												name="radio-buttons-group"
												row
												onChange={handleChangeGender}
											>
												<FormControlLabel
													value="Female"
													control={<Radio />}
													label="Female"
												/>
												<FormControlLabel
													value="Male"
													control={<Radio />}
													label="Male"
												/>
												<FormControlLabel
													value="Other"
													control={<Radio />}
													label="Other"
												/>
											</RadioGroup>
										</FormControl>
									</Grid>
									<Grid item xs={12}>
										<FormControl component="fieldset">
											<FormLabel component="legend">Interested in</FormLabel>
											<FormGroup aria-label="position" row>
												<FormControlLabel
													value="automation_testing"
													control={
														<Checkbox
															color="primary"
															value={interests.automation_testing}
															onChange={handleChangeInterests}
														/>
													}
													label="Automation Testing"
													labelPlacement="end"
												/>
												<FormControlLabel
													value="manual_testing"
													control={
														<Checkbox
															color="secondary"
															defaultChecked
															value={interests.manual_testing}
															onChange={handleChangeInterests}
														/>
													}
													label="Manual Testing"
													labelPlacement="end"
												/>
												<FormControlLabel
													value="development"
													control={
														<Checkbox
															color="success"
															value={interests.development}
															onChange={handleChangeInterests}
														/>
													}
													label="Development"
													labelPlacement="end"
												/>
												<FormControlLabel
													value="management"
													control={
														<Checkbox
															color="default"
															value={interests.management}
															onChange={handleChangeInterests}
														/>
													}
													label="Management"
													labelPlacement="end"
												/>
											</FormGroup>
										</FormControl>
									</Grid>
									<Grid item xs={12}>
										<FormControl sx={{ width: "100%" }}>
											<InputLabel id="multiple-chip-label">
												Languages
											</InputLabel>
											<Select
												labelId="multiple-chip-label"
												id="multiple-chip"
												multiple
												value={languages}
												onChange={handleChangeLanguage}
												input={
													<OutlinedInput
														id="select-multiple-chip"
														label="Languages"
													/>
												}
												renderValue={(selected) => (
													<Box
														sx={{
															display: "flex",
															flexWrap: "wrap",
															gap: 0.5,
														}}
													>
														{selected.map((value) => (
															<Chip key={value} label={value} />
														))}
													</Box>
												)}
												MenuProps={MenuProps}
											>
												{languageList.map((language) => (
													<MenuItem key={language} value={language}>
														<Checkbox
															checked={languages.indexOf(language) > -1}
														/>
														<ListItemText primary={language} />
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</Grid>
									<Grid item xs={12}>
										<FormControl fullWidth>
											<InputLabel id="simple-select-label">Skill</InputLabel>
											<Select
												labelId="simple-select-label"
												id="demo-simple-select"
												value={skill}
												label="Skill"
												onChange={(e) => setSkill(e.target.value)}
											>
												{skillsList.map((skill) => (
													<MenuItem key={skill} value={skill}>
														{skill}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</Grid>
									<Grid item xs={12}>
										<FormControl fullWidth>
											<InputLabel id="country-select-label">Country</InputLabel>
											<Select
												open={countryMenuOpen}
												labelId="country-select-label"
												id="country-select"
												value={country}
												label="Country"
												onClick={() => setCountryMenuOpen(true)}
												onClose={(e) => {}}
												onChange={(e) => {
													if (e.target.value) {
														setCountry(e.target.value);
														setCountryMenuOpen(false);
													} else e.preventDefault();
												}}
												// SelectDisplayProps={{ contentEditable: true }}
											>
												{
													<MenuItem
														unselectable={"true"}
														role="search"
														disableRipple
														focusRipple={false}
														inputMode="search"
														onKeyDown={(e) => {
															if (e.key === "Enter") {
																setCountry(countriesList[0]);
																setCountryMenuOpen(false);
															} else e.stopPropagation();
														}}
														children={
															<TextField
																name="search"
																type="text"
																fullWidth
																placeholder="Search"
																value={countrySearch}
																onChange={(e) => {
																	setCountrySearch(e.target.value);
																}}
																variant="outlined"
															/>
														}
													/>
												}
												{countriesList.map((country) => (
													<MenuItem
														key={country}
														value={country}
														onClick={() => {
															setCountryMenuOpen(false);
														}}
													>
														{country}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</Grid>
									<Grid item container spacing={2} xs={12}>
										<Grid item xs={4}>
											{
												// <FormControl fullWidth>
												// 	<InputLabel id="day-select-label">Day</InputLabel>
												// 	<Select labelId="day-select-label" id="demo-day-select" value={day} label="Day" onChange={(e) => setDay(e.target.value)}>
												// 		{daysList.map((day) => (
												// 			<MenuItem key={day} value={day}>
												// 				{day}
												// 			</MenuItem>
												// 		))}
												// 	</Select>
												// </FormControl>
											}
											<FormControl fullWidth>
												<InputLabel variant="standard" htmlFor="day-select">
													Day
												</InputLabel>
												<NativeSelect
													className="MuiSelect-select MuiSelect-outlined MuiOutlinedInput-input MuiInputBase-input "
													inputProps={{
														name: "day",
														id: "day-select",
													}}
													value={day}
													onChange={(e) => setDay(e.target.value)}
												>
													{daysList.map((day) => (
														<option key={day} value={day}>
															{day}
														</option>
													))}
												</NativeSelect>
											</FormControl>
											{
												// <TextField label="Day" name="day" type="text" fullWidth placeholder="Day" onChange={() => {}} variant="outlined" />
											}
										</Grid>
										<Grid item xs={4}>
											<FormControl fullWidth>
												<InputLabel variant="standard" htmlFor="month-select">
													Month
												</InputLabel>
												<NativeSelect
													className="MuiSelect-select MuiSelect-outlined MuiOutlinedInput-input MuiInputBase-input "
													inputProps={{
														name: "month",
														id: "month-select",
													}}
													value={month}
													onChange={(e) => setMonth(e.target.value)}
												>
													{monthsList.map((month) => (
														<option key={month.value} value={month.value}>
															{month.name}
														</option>
													))}
												</NativeSelect>
												{/*
												<InputLabel id="month-select-label">Month</InputLabel>
												<Select labelId="month-select-label" id="demo-month-select" value={month} label="Month" onChange={(e) => setMonth(e.target.value)}>
													{monthsList.map((month) => (
														<MenuItem key={month} value={month}>
															{month}
														</MenuItem>
													))}
												</Select>
                                            */}
											</FormControl>
										</Grid>
										<Grid item xs={4}>
											<FormControl fullWidth>
												<InputLabel variant="standard" htmlFor="year-select">
													Year
												</InputLabel>
												<NativeSelect
													className="MuiSelect-select MuiSelect-outlined MuiOutlinedInput-input MuiInputBase-input "
													inputProps={{
														name: "year",
														id: "year-select",
													}}
													value={year}
													onChange={(e) => setYear(e.target.value)}
												>
													{yearsList.map(
														(year) =>
															year < new Date().getFullYear() && (
																<option key={year} value={year}>
																	{year}
																</option>
															)
													)}
												</NativeSelect>
											</FormControl>
											{
												// <FormControl fullWidth>
												//     <InputLabel id="year-select-label">Year</InputLabel>
												//     <Select labelId="year-select-label" id="demo-year-select" value={year} label="Year" onChange={(e) => setYear(e.target.value)}>
												//         {yearsList.map((year) => (
												//             <MenuItem key={year} value={year}>
												//                 {year}
												//             </MenuItem>
												//         ))}
												//     </Select>
												// </FormControl>
												// <TextField label="Year" name="year" type="text" fullWidth placeholder="Year" onChange={() => {}} variant="outlined" />
											}
										</Grid>
									</Grid>
									<Grid item xs={12}>
										<TextField
											value={password}
											label="Password"
											name="password"
											type="password"
											fullWidth
											placeholder="Password"
											onChange={handleChangePassword}
											variant="outlined"
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField
											value={confirmPassword}
											label="Confirm Password"
											name="confirmPassword"
											type="password"
											fullWidth
											placeholder="Confirm Password"
											onChange={handleChangeConfirmPassword}
											variant="outlined"
										/>
									</Grid>
									<Grid item container xs={12}>
										<Grid item xs={6} textAlign={"center"}>
											<Button type="submit" variant="contained">
												Submit
											</Button>
										</Grid>
										<Grid item xs={6} textAlign={"center"}>
											<Button
												type="button"
												onClick={handleClearAll}
												variant="contained"
												color="error"
											>
												Clear
											</Button>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Box>
					</Form>
				</FormPageCard>
			</CenterBox>
		</PageContainer>
	);
};

export default Register;
