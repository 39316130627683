import { Box, Button, Tab, Tabs } from '@mui/material';
import React from 'react';
import Anchor from '../../components/Anchor';
import CenterBox from '../../components/CenterBox';
import PageCard from '../../components/PageCard';
import PageContainer from '../../components/PageContainer';
import TabPanel from '../../components/TabPanel';
import { a11yProps } from '../../utils/common';

const Windows = () => {
	const [value, setValue] = React.useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<PageContainer>
			<CenterBox>
				<PageCard>
					<Box
						sx={{
							flexGrow: 1,
							bgcolor: 'background.paper',
							display: 'flex',
							flexDirection: 'column',
							height: '100%',
						}}>
						<Tabs
							// orientation='vertical'
							// variant='scrollable'
							value={value}
							onChange={handleChange}
							aria-label='Vertical tabs example'
							sx={{ borderRight: 1, borderColor: 'divider' }}>
							<Tab
								label='Open New Tab'
								{...a11yProps(0)}
								sx={{ fontSize: '0.75rem' }}
							/>
							<Tab
								label='Open New Window'
								{...a11yProps(1)}
								sx={{ fontSize: '0.75rem' }}
							/>
							<Tab
								label='Open Multiple Windows'
								{...a11yProps(2)}
								sx={{ fontSize: '0.75rem' }}
							/>
						</Tabs>
						<Box
							display={'flex'}
							justifyContent='center'
							width={'100%'}
							marginTop={'3rem'}>
							<TabPanel value={value} index={0} style={{ textAlign: 'center' }}>
								<Anchor
									href='https://automationtesting.in'
									rel='noreferrer'
									target={'_blank'}>
									<Button
										variant='contained'
										color='error'
										sx={{ textDecoration: 'none' }}>
										Open New Tab
									</Button>
								</Anchor>
							</TabPanel>
							<TabPanel value={value} index={1} style={{ textAlign: 'center' }}>
								<Button
									variant='contained'
									color='error'
									onClick={() => {
										window.open(
											'https://automationtesting.in',
											'_blank',
											'toolbar=yes, scrollbars=yes, resizable=yes,fullscreen=yes'
										);
									}}>
									Open New Window
								</Button>
							</TabPanel>
							<TabPanel value={value} index={2} style={{ textAlign: 'center' }}>
								<Button
									variant='contained'
									color='error'
									onClick={() => {
										window.open('https://automationtesting.in', '_blank');
										window.open(
											'https://demo.automationtesting.in/Register.html',
											'_blank',
											'toolbar=yes, scrollbars=yes, resizable=yes, fullscreen=yes'
										);
									}}>
									Open Multiple Separate Windows
								</Button>
							</TabPanel>
						</Box>
					</Box>
				</PageCard>
			</CenterBox>
		</PageContainer>
	);
};

export default Windows;
