import { Box, Button, Tab, Tabs } from '@mui/material';
import React from 'react';
import CenterBox from '../../components/CenterBox';
import PageCard from '../../components/PageCard';
import PageContainer from '../../components/PageContainer';
import TabPanel from '../../components/TabPanel';
import { a11yProps } from '../../utils/common';

const Alerts = () => {
	const [value, setValue] = React.useState(0);
	const [status, setStatus] = React.useState('');
	// const [value, setValue] = React.useState(0);
	// const [value, setValue] = React.useState(0);

	const handleChange = (event, newValue) => {
		setStatus('');
		setValue(newValue);
	};

	return (
		<PageContainer>
			<CenterBox>
				<PageCard>
					<Box
						sx={{
							flexGrow: 1,
							bgcolor: 'background.paper',
							display: 'flex',
							flexDirection: 'column',
							height: '100%',
						}}>
						<Tabs
							// orientation='vertical'
							// variant='scrollable'
							value={value}
							onChange={handleChange}
							aria-label='Vertical tabs example'
							sx={{ borderRight: 1, borderColor: 'divider' }}>
							<Tab
								label='Alert with Ok'
								{...a11yProps(0)}
								sx={{ fontSize: '0.75rem' }}
							/>
							<Tab
								label='Alert with Ok & Cancel'
								{...a11yProps(1)}
								sx={{ fontSize: '0.75rem' }}
							/>
							<Tab
								label='Alert with Textbox'
								{...a11yProps(2)}
								sx={{ fontSize: '0.75rem' }}
							/>
						</Tabs>
						<Box
							display={'flex'}
							justifyContent='center'
							width={'100%'}
							marginTop={'3rem'}>
							<TabPanel value={value} index={0} style={{ textAlign: 'center' }}>
								<Button
									variant='contained'
									color='error'
									onClick={() => {
										alert("Hi! I'm an alert");
										// console.log(alert('something'));
									}}>
									Click here to display an alert
								</Button>
							</TabPanel>
							<TabPanel value={value} index={1} style={{ textAlign: 'center' }}>
								<Button
									variant='contained'
									color='error'
									onClick={() => {
										if (window.confirm('I am an alert box!!')) {
											setStatus('You clicked OK');
										} else {
											setStatus('You clicked Cancel');
										}
									}}>
									Click here to display an alert with OK and Cancel
								</Button>
							</TabPanel>
							<TabPanel value={value} index={2} style={{ textAlign: 'center' }}>
								<Button
									variant='contained'
									color='error'
									onClick={() => {
										const tempName = prompt('Enter your name');
										console.log(tempName);
										if (tempName === null) {
											setStatus('You clicked Cancel');
										} else {
											setStatus(
												`Hi ${tempName ? tempName : 'Tester'}! How are you?`
											);
										}
									}}>
									Click here to display an alert with Text Field
								</Button>
							</TabPanel>
						</Box>
						{status && (
							<span
								className={`alert mx-auto ${
									status.includes('OK') || status.includes('Hi')
										? 'alert-success'
										: 'alert-danger'
								}`}>
								{status}
							</span>
						)}
					</Box>
				</PageCard>
			</CenterBox>
		</PageContainer>
	);
};

export default Alerts;
