import React from 'react';
import { useLocation } from 'react-router';
import { Route, Routes } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import Footer from './components/Footer';
import Header from './components/Header';
import SiteHeading, { SiteHeadingBox } from './components/SiteHeading';
import Alerts from './pages/Alerts/Alerts';
import Datepickers from './pages/Datepickers/Datepickers';
import DnD from './pages/DnD/DnD';
import DynamicData from './pages/DynamicData/DynamicData';
import Login from './pages/Login/Login';
import Register from './pages/Register/Register';
import SelfHealing from './pages/SelfHealing/SelfHealing';
import ShadowDom from './pages/ShadowDom/ShadowDom';
import Sliders from './pages/Sliders/Sliders';
import Text from './pages/Text/Text';
import WebTable from './pages/WebTable/WebTable';
import Windows from './pages/Windows/Windows';
import Frames from './pages/iFrames/Frames';

function App() {
	const { pathname, search } = useLocation();

	return (
		<RecoilRoot>
			<div className='App'>
				{pathname !== '/' && !search.includes('noheader') && (
					<React.Fragment>
						<SiteHeadingBox>
							<SiteHeading>
								Practice your automation testing here 👇
							</SiteHeading>
						</SiteHeadingBox>
						<Header />
					</React.Fragment>
				)}
				<Routes>
					<Route path='/' element={<Login />} />

					<Route path='/form' element={<Register />} />
					<Route path='/table' element={<WebTable />} />
					<Route path='/self-healing-form' element={<SelfHealing />} />
					<Route path='/alerts' element={<Alerts />} />
					<Route path='/windows' element={<Windows />} />
					<Route path='/frames' element={<Frames />} />
					<Route path='/datepickers' element={<Datepickers />} />
					<Route path='/sliders' element={<Sliders />} />
					<Route path='/dragdrop' element={<DnD />} />
					<Route path='/dynamic-data' element={<DynamicData />} />
					<Route path='/text' element={<Text />} />
					<Route path='/shadow-dom' element={<ShadowDom />} />
				</Routes>
				{pathname !== '/' && <Footer />}
			</div>
		</RecoilRoot>
	);
}

export default App;
