import { Box, Link, Typography } from "@mui/material";
import React from "react";
import {
	FaLinkedin,
	FaSquareFacebook,
	FaSquareGooglePlus,
	FaSquareTwitter,
	FaYoutube,
} from "react-icons/fa6";
import styled from "styled-components";

const StyledFooter = styled("footer")({
	width: "100vw",
	position: "fixed",
	bottom: 0,
	// marginTop: "1rem",
	padding: "0.5rem 5rem 0.5rem",
	backgroundColor: "#15354cff",
	color: "white !important",
	boxShadow: "rgba(0, 0, 0, 0.04) -10px 3px 5px",
});

const followUsLinkStyles = {
	width: "50px",
	height: "50px",
	display: "inline-block",
	verticalAlign: "middle",
	borderRadius: "50%",
	color: "white",
	border: "2px dashed white",
	p: "5px",
	backgroundClip: "content-box",
	transition: "0.5s",
	position: "relative",
	"&:hover": {
		color: "white",
		transform: "rotate(360deg)",
		transition: "all 0.5s",
	},
};

const Footer = () => {
	return (
		<StyledFooter>
			<Box
				sx={{
					width: "100%",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					flexDirection: "column",
					gap: "0rem",
				}}
			>
				<Box
					sx={{
						display: "flex",
						gap: "0.5rem",
						justifyContent: "space-between",
						width: "100%",
					}}
				>
					<Box
						sx={{
							display: "flex",
						}}
					>
						<Typography
							className=""
							variant="overline"
							sx={{
								color: "white",
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
								mr: "10px",
								whiteSpace: "nowrap",
							}}
						>
							Visit our{" "}
							<Typography variant="overline" sx={{ px: "5px" }}>
								Automation Testing{" "}
							</Typography>
							website at
							{"  "}{" "}
						</Typography>

						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<Link
								target="_blank"
								href="https://www.facebook.com/automationtesting2016/"
								className=""
								sx={{ ...followUsLinkStyles, backgroundColor: "white" }}
							>
								<img
									src={require("../assets/original.png")}
									alt="logo"
									style={{
										width: "25px",
										position: "absolute",
										top: "50%",
										left: "50%",
										transform: "translate(-52%, -50%)",
									}}
								/>
							</Link>
						</Box>
					</Box>
					<Box sx={{ display: "flex", alignItems: "center" }}>
						<Typography
							variant="caption"
							width={"fit-content"}
							sx={{ display: "flex", alignItems: "center", gap: "5px" }}
						>
							Developed by{" "}
							<Link
								target="_blank"
								rel="noopener noreferrer"
								href="https://www.linkedin.com/in/maniteja-emmadi/"
								sx={{
									color: "white",
									textDecoration: "none",
									fontSize: "16px",
									position: "relative",
									"&::before": {
										content: "''",
										// display: none,
										position: "absolute",
										width: 0,
										height: "2px",
										// border: 2px solid white,
										borderRadius: "10px",
										backgroundColor: "white",
										bottom: 0,
										transition: "0.3s linear",
										left: 0,
									},
									"&:hover": {
										color: "white",
										transition: "0.3s linear",
										"&::before": {
											content: "''",
											position: "absolute",
											width: "100%",
											backgroundColor: "white",
											bottom: 0,
											transition: "0.3s linear",
											left: 0,
										},
									},
								}}
							>
								Maniteja Emmadi
							</Link>
						</Typography>
					</Box>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							gap: "20px",
						}}
					>
						<Typography variant="overline">Follow us at</Typography>
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "center",
								alignItems: "center",
								gap: "10px",
							}}
						>
							<Link
								target="_blank"
								href="https://www.facebook.com/automationtesting2016/"
								className=""
								sx={{ ...followUsLinkStyles, backgroundColor: "#3B5998" }}
							>
								<FaSquareFacebook
									size={20}
									style={{
										position: "absolute",
										top: "50%",
										left: "50%",
										transform: "translate(-50%, -50%)",
									}}
								/>
							</Link>
							<Link
								target="_blank"
								href="https://www.facebook.com/automationtesting2016/"
								className=""
								sx={{ ...followUsLinkStyles, backgroundColor: "#00ACEE" }}
							>
								<FaSquareTwitter
									size={20}
									style={{
										position: "absolute",
										top: "50%",
										left: "50%",
										transform: "translate(-50%, -50%)",
									}}
								/>
							</Link>
							<Link
								target="_blank"
								href="https://www.facebook.com/automationtesting2016/"
								className=""
								sx={{ ...followUsLinkStyles, backgroundColor: "#0177b5" }}
							>
								<FaLinkedin
									size={20}
									style={{
										position: "absolute",
										top: "50%",
										left: "50%",
										transform: "translate(-50%, -50%)",
									}}
								/>
							</Link>
							<Link
								target="_blank"
								href="https://www.facebook.com/automationtesting2016/"
								className=""
								sx={{ ...followUsLinkStyles, backgroundColor: "tomato" }}
							>
								<FaSquareGooglePlus
									size={20}
									style={{
										position: "absolute",
										top: "50%",
										left: "50%",
										transform: "translate(-50%, -50%)",
									}}
								/>
							</Link>
							<Link
								target="_blank"
								href="https://www.facebook.com/automationtesting2016/"
								className=""
								sx={{ ...followUsLinkStyles, backgroundColor: "red" }}
							>
								<FaYoutube
									size={20}
									style={{
										position: "absolute",
										top: "50%",
										left: "50%",
										transform: "translate(-50%, -50%)",
									}}
								/>
							</Link>
						</Box>
					</Box>
				</Box>
			</Box>
		</StyledFooter>
	);
};

export default Footer;
