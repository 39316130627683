import {
	Button,
	Checkbox,
	FormControlLabel,
	FormGroup,
	Grid,
	Input,
	TextField,
	Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { pink } from "@mui/material/colors";
import React, { useState } from "react";
import styled from "styled-components";
import Anchor from "../../components/Anchor";
import CenterBox from "../../components/CenterBox";
import PageContainer from "../../components/PageContainer";
import { Form, FormPageCard } from "../Register/Register";

const CustomDiv = styled.div`
	width: 100%;
	height: 3.5rem;
	border-radius: 4px;
	border: 1px solid;
	border-color: rgba(118, 118, 118, 0.3);
	display: flex;
	align-items: center;
	padding: 1em;
`;

const CustomTextField = styled(TextField)({
	borderColor: "#9c27b0 !important",
});

const SelfHealing = () => {
	const [locatorsChanged, setLocatorsChanged] = useState(false);
	const [tagChangeValue, setTagChangeValue] = useState("");

	const ChangingTag = !locatorsChanged ? TextField : CustomDiv;
	const ChangingIDTag = !locatorsChanged ? TextField : Input;

	const handleChangeLocators = () => {
		setLocatorsChanged((prev) => !prev);
	};

	const handleTagChangeValue = (e) => {
		setTagChangeValue(e.target.value);
	};

	return (
		<PageContainer maxWidth="md">
			<CenterBox>
				<FormPageCard>
					<Form onSubmit={() => {}}>
						<Typography
							variant="h5"
							textAlign={"center"}
							fontWeight={"500"}
							padding={"0 0 1em 0"}>
							Self Healing Locators
						</Typography>
						<Box
							component="div"
							style={{
								paddingBottom: "2rem",
							}}>
							<Grid container spacing={4} columns={{ xs: 4, sm: 8, md: 12 }}>
								<Grid
									container
									item
									spacing={4}
									xs={7}
									columns={{ xs: 4, sm: 8, md: 12 }}>
									<Grid item spacing={2} alignItems={"center"} xs={12}>
										{
											// <Grid item xs={2}>
											// 	<InputLabel>Full Name</InputLabel>
											// </Grid>
										}
										<TextField
											label={!locatorsChanged ? "Change ID" : "ID Changed"}
											name="id_change"
											id={!locatorsChanged ? "currentID" : "newID"}
											type="text"
											fullWidth
											placeholder={
												!locatorsChanged ? "Change ID" : "ID Changed"
											}
											variant="outlined"
										/>
										{
											// errors.email && <Error>{errors.email}</Error>
										}
									</Grid>
									<Grid
										container
										item
										spacing={4}
										alignItems={"center"}
										xs={12}>
										{
											// <Grid item xs={2}>
											// 	<InputLabel>Address</InputLabel>
											// </Grid>
										}
										<Grid item xs={12}>
											<ChangingIDTag
												label="Change ID and Tag Name"
												id={
													!locatorsChanged
														? "id_tag_change"
														: "new_changed_tag_id"
												}
												name="id_tag_change"
												type="text"
												fullWidth
												placeholder={
													!locatorsChanged
														? "Change ID and Tag Name"
														: "ID & Tag Changed"
												}
												multiline={!locatorsChanged}
												minRows={"2"}
												maxRows={"3"}
												variant="outlined"
											/>
											{
												// errors.email && <Error>{errors.email}</Error>
											}
										</Grid>
									</Grid>
									<Grid
										container
										item
										spacing={2}
										alignItems={"center"}
										xs={12}>
										<Grid item xs={12}>
											<CustomTextField
												label="Change Class Name"
												name="class_change"
												className={
													!locatorsChanged ? "firstClass" : "newChangedClass"
												}
												type="text"
												color={locatorsChanged && "secondary"}
												fullWidth
												placeholder="Change Class Name"
												variant="outlined"
												changed={locatorsChanged}
											/>
										</Grid>
									</Grid>
									<Grid
										container
										item
										spacing={2}
										alignItems={"center"}
										xs={12}>
										<Grid item xs={12}>
											<TextField
												label={
													!locatorsChanged ? "Enabled to Disabled" : "Disabled"
												}
												name="enabled_disabled"
												type="text"
												fullWidth
												placeholder={
													!locatorsChanged ? "Enabled to Disabled" : "Disabled"
												}
												disabled={locatorsChanged}
												variant="outlined"
											/>
										</Grid>
									</Grid>
								</Grid>
								<Grid
									container
									item
									spacing={4}
									alignItems="center"
									xs={5}
									justifyItems={"center"}
									columns={{ xs: 4, sm: 8, md: 12 }}>
									<Grid item xs={12}>
										<CenterBox
											style={{
												flexDirection: "column",
												alignContent: "center",
											}}>
											<Typography component="h2">Change Locators</Typography>
											<Button
												variant="contained"
												color="secondary"
												onClick={handleChangeLocators}
												style={{ width: "15em", height: "3em" }}>
												Click Here!
											</Button>
										</CenterBox>
									</Grid>
								</Grid>
								<Grid
									item
									container
									spacing={4}
									xs={7}
									columns={{ xs: 4, sm: 8, md: 12 }}>
									<Grid item xs={12}>
										<ChangingTag
											label="Change Tag Name"
											name="tag_change"
											id="tag_change_id"
											type="text"
											fullWidth
											placeholder="Change Tag Name"
											value={tagChangeValue}
											onChange={handleTagChangeValue}
											variant="outlined">
											{!locatorsChanged
												? null
												: `Changed to div - ${
														tagChangeValue ? tagChangeValue : null
												  }`}
										</ChangingTag>
									</Grid>
									<Grid item xs={12}>
										<CenterBox column>
											<FormGroup>
												<FormControlLabel
													control={<Checkbox defaultChecked />}
													label="Label 1"
												/>
												<FormControlLabel
													control={
														<Checkbox
															defaultChecked
															color={locatorsChanged ? "secondary" : "info"}
														/>
													}
													label="Label 2"
												/>
												<FormControlLabel
													control={
														<Checkbox
															defaultChecked
															color={locatorsChanged ? "success" : "warning"}
														/>
													}
													label="Label 3"
												/>
												<FormControlLabel
													control={
														<Checkbox
															defaultChecked
															color={locatorsChanged ? "default" : "error"}
														/>
													}
													label="Label 4"
												/>
												<FormControlLabel
													control={
														<Checkbox
															defaultChecked
															sx={
																locatorsChanged && {
																	color: pink[800],
																	"&.Mui-checked": {
																		color: pink[600],
																	},
																}
															}
														/>
													}
													label="Label 5"
												/>
											</FormGroup>
										</CenterBox>
									</Grid>
									<Grid item xs={12}>
										<TextField
											label={!locatorsChanged ? "Change Name" : "Name Changed"}
											name={
												!locatorsChanged ? "unchangedName" : "newChangedName"
											}
											type="text"
											fullWidth
											placeholder={
												!locatorsChanged ? "Change Name" : "Name Changed"
											}
											variant="outlined"
										/>
									</Grid>
									<Grid item xs={6}>
										<CenterBox>
											<Anchor
												href={"https://automationtesting.in"}
												target="_blank"
												rel="noopener noreferrer">
												<Button>
													{!locatorsChanged ? "Link Text" : "Changed Text"}
												</Button>
											</Anchor>
										</CenterBox>
									</Grid>
								</Grid>
							</Grid>
						</Box>
					</Form>
				</FormPageCard>
			</CenterBox>
		</PageContainer>
	);
};

export default SelfHealing;
