import { Box } from '@mui/system';
import styled from 'styled-components';

const SiteHeading = styled.h3`
	max-width: 100vw;
	width: 100%;
	text-align: center;
	text-transform: capitalize;
	margin: 0;
	margin-right: 5rem;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-size: 28px;
`;

export default SiteHeading;

export const SiteHeadingBox = styled(Box)({
	width: '100%',
	maxHeight: '3rem',
	position: 'fixed',
	backgroundColor: 'white',
	display: 'flex',
	alignItems: 'center',
	zIndex: '500',
});
