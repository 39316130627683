import { ArrowDropDown, ChevronRight } from "@mui/icons-material";
import { Box } from "@mui/material";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import CenterBox from "./CenterBox";
import Dropdown, { DropdownItem } from "./Dropdown";
import ImageBox from "./ImageBox";

const StyledHeader = styled(Box)({
	display: 'flex',
	flexDirection: 'row',
	width: "100vw",
	height: "3.5rem",
	padding: "0 0.5em",
	backgroundColor: "#00529c !important",
	color: "white !important",
	position: "fixed",
	boxShadow: "rgba(0, 0, 0, 0.04) 0px 3px 5px",
	top: "calc(2rem)",
	zIndex: "5000",
});

const HeaderBox = styled(CenterBox)({
	// display: 'flex',
	// alignContent: 'space-between',
	maxWidth: "100%",
	justifyContent: "space-evenly",
});

const activeStyle = { backgroundColor: "#1f415a !important" };

// const StyledNavLink = styled(NavLink)({
// 	width: "3em",
// 	height: "50%",
// 	textDecoration: "none",
// 	color: "white",
// 	padding: "0 0.5rem",
// });
const StyledNav = styled(({ component, ...props }) =>
	React.cloneElement(component, props)
)`
	height: 100%;
	display: flex;
	align-items: center;
	text-decoration: none;
	color: white;
	padding: 0 0.5rem;
	position: relative;

	&::before {
		content: "";
		// display: none;
		position: absolute;
		width: 0;
		height: 3px;
		// border: 2px solid white;
		border-radius: 10px;
		background-color: white;
		bottom: 0;
		transition: 0.3s linear;
		left: 0;
	}

	&:hover {
		background-color: #1f415a !important;
		color: white;
		transition: 0.3s linear;
		&::before {
			content: "";
			// display: unset;
			position: absolute;
			width: 100%;
			// border: 2px solid white;
			// border-radius: 10px;
			background-color: white;
			bottom: 0;
			transition: 0.3s linear;
			left: 0;
		}
	}
`;

const NavItem = (props) => {
	const { label, children, isDropdown, component = <span /> } = props;
	const [open, setOpen] = useState(false);

	return (
		<StyledNav
			component={component}
			onClick={() => setOpen(!open)}
			onMouseEnter={() => setOpen(true)}
			onMouseLeave={() => setOpen(false)}
			// onMouseOver={() => setOpen(!open)}
		>
			<span
				style={{
					maxWidth: "6rem",
					overflow: "hidden",
					textOverflow: "ellipsis",
					whiteSpace: "nowrap",
				}}
			>
				{label}
			</span>{" "}
			{isDropdown && <ArrowDropDown />}
			{open && children}
		</StyledNav>
	);
};

const Header = () => {
	const setActiveStyle = ({ isActive }) => (isActive ? activeStyle : undefined);

	return (
		<header>
			<CenterBox sx={{ flexDirection: "row" }}>
				<StyledHeader>
				<Box width={"3rem"} marginLeft={"2rem"} sx={{ display: "flex", alignItems: "center" }}>
					<a href="https://www.automationtesting.in">
						<ImageBox src={require("../assets/original.png")} />
					</a>
				</Box>
					<HeaderBox>
						<NavItem
							style={setActiveStyle}
							label={"Home"}
							component={<NavLink to={"/"} />}
						/>
						<NavItem
							style={setActiveStyle}
							label={"Form"}
							component={<NavLink to={"/form"} style={{ padding: "0 1rem" }} />}
						/>
						<NavItem
							style={setActiveStyle}
							label={"WebTable"}
							component={<NavLink to={"/table"} />}
						/>
						<NavItem
							style={setActiveStyle}
							label={"Self Healing"}
							component={<NavLink to={"/self-healing-form"} />}
						/>
						<NavItem label={"Switch-To"} isDropdown>
							<Dropdown>
								<DropdownItem
									component={<NavLink to={"/alerts"} />}
									//
								>
									Alerts
								</DropdownItem>
								<DropdownItem component={<NavLink to={"/windows"} />}>
									Windows
								</DropdownItem>
								<DropdownItem component={<NavLink to={"/frames"} />}>
									Frames
								</DropdownItem>
							</Dropdown>
						</NavItem>

						<NavItem label={"Widgets"} isDropdown>
							<Dropdown>
								<DropdownItem component={<NavLink to={"/alerts"} />}>
									Accordion
								</DropdownItem>
								<DropdownItem component={<NavLink to={"/alerts"} />}>
									Autocomplete
								</DropdownItem>
								<DropdownItem component={<NavLink to={"/datepickers"} />}>
									Datepicker
								</DropdownItem>
								<DropdownItem component={<NavLink to={"/sliders"} />}>
									Slider
								</DropdownItem>
							</Dropdown>
						</NavItem>
						<NavItem label={"Interactions"} isDropdown>
							<Dropdown>
								<DropdownItem
									style={{ position: "relative" }}
									rightIcon={<ChevronRight />}
									component={<div />}
								>
									Drag and Drop
									<Dropdown
										style={{ position: "absolute", left: "152%", top: "0%" }}
										isDropdown
									>
										<DropdownItem component={<NavLink to={"/dragdrop"} />}>
											Static
										</DropdownItem>
										<DropdownItem component={<NavLink to={"/sliders"} />}>
											Dynamic
										</DropdownItem>
									</Dropdown>
								</DropdownItem>
								<DropdownItem component={<NavLink to={"/alerts"} />}>
									Selectable
								</DropdownItem>
								<DropdownItem component={<NavLink to={"/alerts"} />}>
									Resizable
								</DropdownItem>
							</Dropdown>
						</NavItem>
						<NavItem label={"Video"} isDropdown>
							<Dropdown>
								<DropdownItem component={<NavLink to={"/alerts"} />}>
									Youtube
								</DropdownItem>
								<DropdownItem component={<NavLink to={"/alerts"} />}>
									Vimeo
								</DropdownItem>
							</Dropdown>
						</NavItem>
						<NavItem label={"WYSIWYG"} isDropdown>
							<Dropdown>
								<DropdownItem component={<NavLink to={"/alerts"} />}>
									TinyMCE
								</DropdownItem>
								<DropdownItem component={<NavLink to={"/alerts"} />}>
									CKEditor
								</DropdownItem>
								<DropdownItem component={<NavLink to={"/alerts"} />}>
									Summernote
								</DropdownItem>
								<DropdownItem component={<NavLink to={"/alerts"} />}>
									CodeMirror
								</DropdownItem>
							</Dropdown>
						</NavItem>
						<NavItem label={"More"} isDropdown>
							<Dropdown>
								<DropdownItem component={<NavLink to={"/text"} />}>
									Text
								</DropdownItem>
								<DropdownItem component={<NavLink to={"/alerts"} />}>
									Charts
								</DropdownItem>
								<DropdownItem component={<NavLink to={"/dynamic-data"} />}>
									Dynamic Data
								</DropdownItem>
								<DropdownItem component={<NavLink to={"/file-download"} />}>
									File Download
								</DropdownItem>
								<DropdownItem component={<NavLink to={"/alerts"} />}>
									File Upload
								</DropdownItem>
								<DropdownItem component={<NavLink to={"/alerts"} />}>
									ProgressBar
								</DropdownItem>
								<DropdownItem component={<NavLink to={"/alerts"} />}>
									Loader
								</DropdownItem>
								<DropdownItem component={<NavLink to={"/alerts"} />}>
									Modals
								</DropdownItem>
								<DropdownItem component={<NavLink to={"/shadow-dom"} />}>
									Shadow DOM
								</DropdownItem>
							</Dropdown>
						</NavItem>

						<NavItem
							style={setActiveStyle}
							label={"Practice Site"}
							// eslint-disable-next-line jsx-a11y/anchor-has-content
							component={<a href={"https://practice.automationtesting.in"} />}
						/>
					</HeaderBox>
				</StyledHeader>
			</CenterBox>
		</header>
	);
};

export default Header;
